import React, { useState } from "react";
import "../styles/Product.css";
import { useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { VscSettings } from "react-icons/vsc";

const data = [
  {
    id: 1,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 2,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 3,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 4,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 5,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
  {
    id: 6,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 7,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 8,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 9,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 10,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
  {
    id: 11,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 12,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 13,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 14,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 15,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
  {
    id: 16,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 17,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 18,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 19,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 20,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
];

const Product = () => {
  const { category } = useParams();
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="productMainContainer">
      <div className="productMainSidebar">
        <div className="filterHeader">
          <VscSettings />
          Filter
        </div>
        <div className="filter-section">
          <button
            className="filter-button"
            onClick={() => toggleDropdown("collection")}
          >
            Collection{" "}
            {openDropdown === "collection" ? <FaAngleUp /> : <FaAngleDown />}
          </button>
          {openDropdown === "collection" && (
            <ul className="filter-list">
              <li>Polo T-Shirts</li>
              <li>Plain T-Shirts</li>
              <li>Oversized T-Shirts</li>
              <li>Hooded T-Shirts</li>
              <li>Jeans</li>
              <li>Skinny Jeans</li>
              <li>Slim Fit Jeans</li>
              <li>Cargo Trousers</li>
              <li>Chinos</li>
              <li>Casual Shirts</li>
              <li>Formal Shirts</li>
              <li>Denim Shirts</li>
            </ul>
          )}
        </div>

        <div className="filter-section">
          <button
            className="filter-button"
            onClick={() => toggleDropdown("color")}
          >
            Color {openDropdown === "color" ? <FaAngleUp /> : <FaAngleDown />}
          </button>
          {openDropdown === "color" && (
            <ul className="filter-list">
              <li>Red</li>
              <li>Blue</li>
              <li>Green</li>
              <li>Black</li>
              <li>White</li>
              <li>Grey</li>
              <li>Yellow</li>
              <li>Orange</li>
              <li>Pink</li>
              <li>Purple</li>
              <li>Brown</li>
              <li>Beige</li>
            </ul>
          )}
        </div>

        <div className="filter-section">
          <button
            className="filter-button"
            onClick={() => toggleDropdown("size")}
          >
            Size {openDropdown === "size" ? <FaAngleUp /> : <FaAngleDown />}
          </button>
          {openDropdown === "size" && (
            <ul className="filter-list">
              <li>S (Small)</li>
              <li>M (Medium)</li>
              <li>L (Large)</li>
              <li>XL (Extra Large)</li>
              <li>XXL (2XL - Double Extra Large)</li>
              <li>XXXL (3XL - Triple Extra Large)</li>
            </ul>
          )}
        </div>

        <div className="filter-section">
          <button
            className="filter-button"
            onClick={() => toggleDropdown("patterns")}
          >
            Patterns{" "}
            {openDropdown === "patterns" ? <FaAngleUp /> : <FaAngleDown />}
          </button>
          {openDropdown === "patterns" && (
            <ul className="filter-list">
              <li>Stripes</li>
              <li>Polka Dots</li>
              <li>Floral</li>
              <li>Checked</li>
              <li>Herringbone</li>
              <li>Paisley</li>
              <li>Houndstooth</li>
              <li>Camouflage</li>
              <li>Abstract</li>
              <li>Geometric</li>
              <li>Tie-Dye</li>
              <li>Ombre</li>
            </ul>
          )}
        </div>

        <div className="filter-section">
          <button
            className="filter-button"
            onClick={() => toggleDropdown("fabric")}
          >
            Fabric {openDropdown === "fabric" ? <FaAngleUp /> : <FaAngleDown />}
          </button>
          {openDropdown === "fabric" && (
            <ul className="filter-list">
              <li>Cotton</li>
              <li>Silk</li>
              <li>Wool</li>
              <li>Linen</li>
              <li>Denim</li>
              <li>Polyester</li>
              <li>Rayon</li>
              <li>Nylon</li>
              <li>Velvet</li>
              <li>Chiffon</li>
            </ul>
          )}
        </div>

        <div className="filter-section">
          <button
            className="filter-button"
            onClick={() => toggleDropdown("priceRange")}
          >
            Price Range{" "}
            {openDropdown === "priceRange" ? <FaAngleUp /> : <FaAngleDown />}
          </button>
          {openDropdown === "priceRange" && (
            <ul className="filter-list">
              <li>₹0 - ₹500</li>
              <li>₹500 - ₹1000</li>
              <li>₹1000 - ₹2000</li>
              <li>₹2000 - ₹3000</li>
              <li>₹3000 - ₹4000</li>
              <li>₹4000 - ₹5000</li>
              <li>₹5000+</li>
            </ul>
          )}
        </div>
      </div>
      <div className="productMainContent">
        <div className="sortDivMain">
          <div className="productheadingContainer">
            <h3>
              Products <span>({data.length})</span>
            </h3>
          </div>
          <div className="sort-section">
            <button
              className="sort-button"
              onClick={() => toggleDropdown("sort")}
            >
              Sort By{" "}
              {openDropdown === "sort" ? <FaAngleUp /> : <FaAngleDown />}
            </button>
            {openDropdown === "sort" && (
              <ul className="sort-list">
                <li>Trending</li>
                <li>Newly Launched</li>
                <li>Price: Low To High</li>
                <li>Price: High To Low</li>
              </ul>
            )}
          </div>
        </div>
        <ProductCard data={data} />
      </div>
    </div>
  );
};

export default Product;
