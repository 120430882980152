import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import {
  FaUser,
  FaEnvelope,
  FaMapMarker,
  FaCreditCard,
  FaMoneyBill,
} from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { BsFillSendFill } from "react-icons/bs";

import "../styles/Checkout.css";

const Checkout = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    otp: "",
    phone: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    paymentMethod: "cod",
  });

  const [showModal, setShowModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setShowModal(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };

  if (!showModal) return null;

  return (
    <>
      <div
        className={`cart-overlay ${isOpen ? "fade-in" : "fade-out"}`}
        onClick={onClose}
      />
      <div className={`cart-modal ${isClosing ? "slide-out" : "slide-in"}`}>
        <div className="cart-header">
          <h2 style={{ color: "black" }}>Checkout</h2>
          <button className="close-button" onClick={onClose}>
            <IoCloseOutline size={30} />
          </button>
        </div>
        <div className="cart-content">
          <form onSubmit={handleSubmit}>
            <div className="checkoutFieldsContainer">
              <div className="form-group">
                <label htmlFor="name">
                  <FaUser className="icon" />
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">
                  <MdCall className="icon" /> Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </div>
            <div className="checkoutFieldsContainer">
              <div className="form-group">
                <label htmlFor="email">
                  <FaEnvelope className="icon" /> Email
                </label>
                <div className="innerForm">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                    style={{ border: "none" }}
                  />
                  <BsFillSendFill />
                </div>
              </div>
              {/* OTP Verification */}
              <div className="form-group">
                <label htmlFor="otp">
                  <FaEnvelope className="icon" /> OTP
                </label>
                <div className="innerForm">
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    placeholder="Enter OTP"
                    required
                    style={{ border: "none" }}
                  />
                  <button>Verify</button>
                </div>
              </div>

              {/* Phone Number Field */}
            </div>
            {/* Address Field */}
            <div className="form-group">
              <label htmlFor="address">
                <FaMapMarker className="icon" /> Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Enter your address"
                required
              />
            </div>

            <div className="addressFlex">
              {/* Pincode Field */}
              <div className="form-group">
                <label htmlFor="pincode">
                  <FaMapMarker className="icon" /> Pincode
                </label>
                <input
                  type="text"
                  id="pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  placeholder="Enter your pincode"
                  required
                />
              </div>

              {/* City Field */}
              <div className="form-group">
                <label htmlFor="city">
                  <FaMapMarker className="icon" /> City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Enter your city"
                  required
                />
              </div>

              {/* State Field */}
              <div className="form-group">
                <label htmlFor="state">
                  <FaMapMarker className="icon" /> State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="Enter your state"
                  required
                />
              </div>
            </div>

            {/* Payment Method */}
            <div className="form-group payment-method">
              <label>
                <FaCreditCard className="icon" /> Payment Method
              </label>
              <div className="payment-options">
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="cod"
                    checked={formData.paymentMethod === "cod"}
                    onChange={handleChange}
                  />
                  Cash on Delivery
                </label>
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="razorpay"
                    checked={formData.paymentMethod === "razorpay"}
                    onChange={handleChange}
                  />
                  Razorpay (Cards, UPI, Scan & Pay)
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="cart-footer" style={{ marginTop: 20 }}>
          <button
            type="submit"
            className="checkout-button"
            onClick={handleSubmit}
          >
            Place Order
          </button>
        </div>
      </div>
    </>
  );
};

export default Checkout;
