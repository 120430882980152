import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/ProductDetails.css";
import { IoBagHandleOutline } from "react-icons/io5";
import { IoCartOutline } from "react-icons/io5";
import ProductCard from "../components/ProductCard";

const datas = [
  {
    id: 1,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    buyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    ratings: 4.5,
    colors: ["Black", "White", "Blue", "Gold", "Green", "Purple", "Violet"],
    sizes: ["S", "M", "L", "XL", "XXL"],
    vendor: "VOGA",
    description: [
      "Premium quality fabric for comfort",
      "Classic polo collar with button placket",
      "Soft and breathable material",
      "Available in multiple sizes and colors",
      "Perfect for casual and semi-formal occasions",
    ],
    images: [
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
    ],
  },
];

const data = [
  {
    id: 1,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 2,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 3,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 4,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 5,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
  {
    id: 6,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 7,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 8,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 9,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 10,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
  {
    id: 11,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 12,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 13,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 14,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 15,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
  {
    id: 16,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 17,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 18,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 19,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 20,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
];

const ProductDetails = () => {
  const location = useLocation();
  const id = location.state?.id;
  const product = datas.find((item) => item.id === id) || datas[0];

  const [mainImage, setMainImage] = useState(product.images[0]);

  return (
    <div className="product-container">
      <div className="product-container-split">
        <div className="image-gallery">
          <div className="thumbnail-images">
            {product.images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt="Thumbnail"
                className={mainImage === img ? "active" : ""}
                onClick={() => setMainImage(img)}
              />
            ))}
          </div>
          <img src={mainImage} alt="Main Product" className="main-image" />
        </div>

        <div className="product-details">
          <h2 className="product-title">{product.title}</h2>
          <p className="product-vendor">Vendor: {product.vendor}</p>
          <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
            <p style={{ fontSize: 20 }}>{product.ratings}</p>
            <div className="stars">
              {[...Array(5)].map((_, index) => (
                <span key={index} className="star active">
                  ★
                </span>
              ))}
            </div>
          </div>
          <p style={{ fontSize: 16, color: "lightgrey" }}>10 Ratings</p>

          <p className="product-price">
            <b>₹{product.salesPrice}</b> <del>₹{product.buyPrice}</del>{" "}
            <span className="discount">({product.discount}% OFF)</span>
          </p>

          <p>
            <b>Size:</b>
          </p>
          <div className="size-options">
            {product.sizes.map((size) => (
              <button key={size}>{size}</button>
            ))}
          </div>

          <p>
            <b>Color:</b>
          </p>
          <div className="color-options">
            {product.colors.map((color) => (
              <span
                key={color}
                style={{
                  background: color.toLowerCase(),
                  border: "1px solid white",
                  cursor: "pointer",
                }}
              ></span>
            ))}
          </div>

          <p>
            <b>Description:</b>
          </p>
          <ul className="product-description">
            {product.description.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>

          <div className="button-group">
            <button className="add-to-cart">
              <IoBagHandleOutline /> Add to Cart
            </button>
            <button className="buy-now">
              <IoCartOutline /> Buy Now
            </button>
          </div>
        </div>
      </div>

      <h3 className="newArrivalTagLine" style={{ fontSize: 20 }}>
        YOU MAY ALSO LIKE
      </h3>
      <ProductCard data={data} width="19%" />
    </div>
  );
};

export default ProductDetails;
