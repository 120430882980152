import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import { FiMinus } from "react-icons/fi";
import Checkout from "./Checkout";
import "../styles/Cart.css";

const Cart = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      image:
        "https://www.beyoung.in/api/catalog/homepage-nov/17feb25/cotton/rust_brown_diagonal_striped_shirt_base.jpg",
      title: "Black Polo Tshirt For Men",
      vendor: "Kamlesh",
      quantity: 1,
      price: 1399,
      dicountPrice: 1099,
      size: "M",
    },
    {
      id: 2,
      image:
        "https://www.beyoung.in/api/catalog/homepage-nov/homepage-jan25/Season_fabric/Products/Flannel/flannel-shirts-3.jpg",
      title: "Black Polo Tshirt For Men",
      vendor: "Kamlesh",
      quantity: 1,
      price: 1399,
      dicountPrice: 1099,
      size: "M",
    },
    {
      id: 3,
      image:
        "https://www.beyoung.in/api/catalog/homepage-nov/homepage-jan25/Season_fabric/Products/Flannel/flannel-shirts-3.jpg",
      title: "Black Polo Tshirt For Men",
      vendor: "Kamlesh",
      quantity: 1,
      price: 1399,
      dicountPrice: 1099,
      size: "M",
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setShowModal(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleCheckoutClick = () => {
    setIsCheckoutOpen(true);
  };

  const handleQuantityChange = (id, delta) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, quantity: Math.max(1, item.quantity + delta) }
          : item
      )
    );
  };

  const handleRemoveItem = (id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  // Calculate subtotal and subtotal with discount
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const subtotalWithDiscount = cartItems.reduce(
    (total, item) => total + item.dicountPrice * item.quantity,
    0
  );

  if (!showModal) return null;

  return (
    <>
      <div
        className={`cart-overlay ${isOpen ? "fade-in" : "fade-out"}`}
        onClick={onClose}
      />
      <div className={`cart-modal ${isClosing ? "slide-out" : "slide-in"}`}>
        <div className="cart-header">
          <h2 style={{ color: "black" }}>My Cart</h2>
          <button className="close-button" onClick={onClose}>
            <IoCloseOutline size={30} />
          </button>
        </div>
        <div className="cart-content">
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <img src={item.image} alt={item.title} className="item-image" />
              <div className="item-details">
                <h3>{item.title}</h3>
                <p>
                  <span>Vendor:</span> {item.vendor}
                </p>
                <p>
                  <span>Size:</span> {item.size}
                </p>
                <p
                  className="removeCartBtn"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  Remove
                </p>
              </div>
              <div className="cartRightCard">
                <div className="item-quantity">
                  <button onClick={() => handleQuantityChange(item.id, -1)}>
                    <FiMinus />
                  </button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleQuantityChange(item.id, 1)}>
                    <IoAdd />
                  </button>
                </div>
                <p>₹{item.dicountPrice}</p>
                <h4>₹{item.price}</h4>
              </div>
            </div>
          ))}
        </div>
        <div className="cartSubtotalContainer">
          <div className="cartSubtotalTop">
            <p>₹{subtotal}</p>
          </div>
          <div className="cartSubtotalBottom">
            <p>Subtotal</p>
            <div>
              <p>{cartItems.length} Items</p>
              <p className="subtotalAmt">₹{subtotalWithDiscount}</p>
            </div>
          </div>
        </div>
        <div className="cart-footer">
          <button className="checkout-button" onClick={handleCheckoutClick}>
            Continue To Checkout
          </button>
        </div>
      </div>
      <Checkout
        isOpen={isCheckoutOpen}
        onClose={() => setIsCheckoutOpen(false)}
      />
    </>
  );
};

export default Cart;
