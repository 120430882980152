import React, { useState } from "react";
import {
  FaUser,
  FaLock,
  FaEnvelope,
  FaSignInAlt,
  FaUserPlus,
  FaCheck,
} from "react-icons/fa";
import "../styles/Authentication.css";
import logo from "../assets/VOGA.png";
import { MdCall } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Authentication = () => {
  const navigate = useNavigate();
  const [isLoginVisible, setIsLoginVisible] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");

  const toggleForm = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  const handleSendOtp = () => {
    setOtpSent(true);
  };

  const handleVerifyOtp = () => {};

  return (
    <div className="authentication-container">
      <img
        onClick={() => navigate("/")}
        src={logo}
        alt="logo"
        className="logo"
      />
      <div class="right-glow"></div>
      <div className={`form-container ${isLoginVisible ? "login" : "signup"}`}>
        <div className="side left-side">
          {isLoginVisible ? (
            <div className="welcome-content">
              <h2>Welcome Back!</h2>
              <p>Step into style. Sign in to explore the latest trends.</p>
              <button onClick={toggleForm}>
                <FaUserPlus /> Sign Up
              </button>
            </div>
          ) : (
            <div className="form signup-form">
              <h2>Sign Up</h2>
              <form>
                <div className="input-row">
                  <div className="input-container">
                    <FaUser className="icon" />
                    <input type="text" placeholder="Name" />
                  </div>
                  <div className="input-container">
                    <FaEnvelope className="icon" />
                    <input type="email" placeholder="Email" />
                  </div>
                </div>
                <div className="input-container withoutpaddinginput">
                  <MdCall className="icon" />
                  <input type="tel" placeholder="Phone Number" />
                  <button
                    type="button"
                    className="send-otp-button"
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </button>
                </div>
                {otpSent && (
                  <div className="input-container withoutpaddinginput">
                    <FaCheck className="icon" />
                    <input
                      type="text"
                      placeholder="Verify OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <button
                      type="button"
                      className="verify-otp-button"
                      onClick={handleVerifyOtp}
                    >
                      Verify
                    </button>
                  </div>
                )}
                <div className="input-container">
                  <FaLock className="icon" />
                  <input type="password" placeholder="Password" />
                </div>
                <button type="submit">
                  <FaUserPlus /> Sign Up
                </button>
              </form>
            </div>
          )}
        </div>

        <div className="side right-side">
          {isLoginVisible ? (
            <div className="form login-form">
              <h2>Login</h2>
              <form>
                <div className="input-container">
                  <FaEnvelope className="icon" />
                  <input type="email" placeholder="Email" />
                </div>
                <div className="input-container">
                  <FaLock className="icon" />
                  <input type="password" placeholder="Password" />
                </div>
                <p className="forgotPasswordText">Forgot password?</p>
                <button type="submit">
                  <FaSignInAlt /> Login
                </button>
              </form>
            </div>
          ) : (
            <div className="welcome-content">
              <h2>Join the Fashion Revolution!</h2>
              <p>Sign up to unlock exclusive styles and offers.</p>
              <button onClick={toggleForm}>
                <FaSignInAlt /> Login
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Authentication;
