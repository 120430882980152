import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 20px",
        lineHeight: "1.8",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--secondary)",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "30px",
        }}
      >
        Privacy Policy
      </h1>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        At VOGA, we are committed to protecting your privacy. This Privacy
        Policy explains how we collect, use, and safeguard your personal
        information when you use our website or services. By using our website,
        you agree to the terms outlined in this policy. If you do not agree with
        any part of this policy, you must not use our website.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Data Collection
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We collect personal information such as your name, email address,
        shipping address, phone number, and payment details when you place an
        order. This information is necessary to process your order, provide
        customer support, and improve your shopping experience. Additionally, we
        may collect non-personal information such as your IP address, browser
        type, and browsing behavior to analyze website performance and enhance
        user experience.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Data Usage
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Your personal information is used solely for the following purposes:
      </p>
      <ul
        style={{
          fontSize: "1.1rem",
          marginBottom: "20px",
          paddingLeft: "20px",
        }}
      >
        <li>Processing and fulfilling your orders.</li>
        <li>Communicating with you about your order status and updates.</li>
        <li>Providing customer support and resolving issues.</li>
        <li>Improving our website, products, and services.</li>
        <li>
          Sending promotional offers and newsletters (only with your consent).
        </li>
      </ul>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We do not sell, trade, or share your personal information with third
        parties without your explicit consent, except as required by law or to
        fulfill our services (e.g., sharing your address with a delivery
        partner).
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Data Security
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We take data security seriously and use industry-standard encryption
        technologies to protect your personal information during transmission
        and storage. Our website is secured with SSL (Secure Socket Layer)
        encryption, ensuring that your data is safe when you place an order or
        provide sensitive information.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        However, no method of online transmission or storage is 100% secure.
        While we strive to protect your data, we cannot guarantee absolute
        security. If you have any concerns about the security of your
        information, please contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        .
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Cookies and Tracking
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Our website uses cookies and similar tracking technologies to enhance
        your browsing experience. Cookies are small files stored on your device
        that help us remember your preferences, analyze website traffic, and
        personalize content. You can disable cookies in your browser settings,
        but this may affect the functionality of our website.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Your Rights
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        You have the right to access, update, or delete your personal
        information at any time. If you would like to review or modify your
        data, please contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        . You can also opt out of receiving promotional emails by clicking the
        "unsubscribe" link in any email.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Changes to This Policy
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. Any changes will be posted on
        this page, and your continued use of our website constitutes your
        acceptance of the updated policy.
      </p>

      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
