import React, { useEffect } from "react";

const ReturnAndRefund = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 20px",
        lineHeight: "1.8",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--secondary)",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "30px",
        }}
      >
        Returns & Refunds
      </h1>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We want you to be completely satisfied with your purchase. If you're not
        happy with your order, here's how you can return it and get a refund:
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Return Policy
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        You can return any item within 5 days of receiving your order. The item
        must be unused, in its original packaging, and with all tags attached.
        Please contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>{" "}
        to initiate a return.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Refund Process
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Once we receive your returned item, we will inspect it and process your
        refund within 5–7 business days. Refunds will be issued to the original
        payment method. Please note that shipping costs are non-refundable.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Exchanges
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        If you'd like to exchange an item, please return the original item and
        place a new order for the desired product. We'll process your refund
        once the returned item is received.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Frequently Asked Questions
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        <strong>Q: How long does it take to process a refund?</strong>
        <br />
        A: Refunds are processed within 5–7 business days after we receive the
        returned item.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        <strong>
          Q: Can I return a product without the original packaging?
        </strong>
        <br />
        A: No, the item must be in its original packaging with all tags attached
        to be eligible for a return.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        <strong>Q: What if my item is damaged or defective?</strong>
        <br />
        A: If your item is damaged or defective, please contact us immediately
        at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        , and we will assist you with a replacement or refund.
      </p>
    </div>
  );
};

export default ReturnAndRefund;
