import React, { useState } from "react";
import "../styles/Orders.css";

const Orders = () => {
  const orders = [
    {
      id: 1,
      image:
        "https://www.beyoung.in/api/catalog/homepage-nov/17feb25/cotton/pale_grey_embroidered_applique_patch_shirt_zoom_view.jpg",
      title: "Premium Leather Jacket",
      price: "₹1299.00",
      paymentStatus: "Paid",
      deliveryStatus: "Shipped",
      color: "Black",
      size: "L",
      deliveryAddress: "123 Premium St, Luxury City, LC 12345",
      email: "test@gmail.com",
      phone: "9876543210",
    },
    {
      id: 2,
      image:
        "https://www.beyoung.in/api/catalog/homepage-nov/17feb25/cotton/rust_brown_diagonal_striped_shirt_base.jpg",
      title: "Premium Leather shirt",
      price: "₹999.00",
      paymentStatus: "Pending",
      deliveryStatus: "Processing",
      color: "Gold",
      size: "M",
      deliveryAddress: "123 Premium St, Luxury City, LC 12345",
      email: "test1@gmail.com",
      phone: "9876543210",
    },
    {
      id: 3,
      image:
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/soft_beige_textured_zipper_polo_t-shirt_base_12_02_2025_700x933.jpg",
      title: "Premium Polo rshirt",
      price: "₹799.00",
      paymentStatus: "Pending",
      deliveryStatus: "Processing",
      color: "Gold",
      size: "S",
      deliveryAddress: "123 Premium St, Luxury City, LC 12345",
      email: "test2@gmail.com",
      phone: "9876543210",
    },
  ];

  const [ratings, setRatings] = useState({});

  const handleStarClick = (orderId, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [orderId]: rating,
    }));
  };

  return (
    <div className="orders-container">
      <h1 className="orders-title">Your Orders</h1>
      {orders.map((order) => (
        <div key={order.id} className="order-card">
          <div className="order-image">
            <img src={order.image} alt={order.title} />
          </div>
          <div className="order-details">
            <h2 className="order-title">{order.title}</h2>
            <p className="order-price">{order.price}</p>
            <div className="order-info">
              <p>
                <span className="info-label">Color:</span> {order.color}
              </p>
              <p>
                <span className="info-label">Size:</span> {order.size}
              </p>
              <p>
                <span className="info-label">Delivery Address:</span>{" "}
                {order.deliveryAddress}
              </p>
              <p>
                <span className="info-label">Email:</span> {order.email}
              </p>
              <p>
                <span className="info-label">Phone:</span> {order.phone}
              </p>
              <p>
                <span className="info-label">Payment Status:</span>{" "}
                <span className={`status ${order.paymentStatus.toLowerCase()}`}>
                  {order.paymentStatus}
                </span>
              </p>
              <div className="flexItems">
                <p className="deliveryStatus">
                  <span className="info-label">Delivery Status:</span>{" "}
                  <span
                    className={`status ${order.deliveryStatus.toLowerCase()}`}
                  >
                    {order.deliveryStatus}
                  </span>
                </p>
                <div className="star-rating">
                  <p className="rating-label">Tap to Rate</p>
                  <div className="stars">
                    {[...Array(5)].map((_, index) => (
                      <span
                        key={index}
                        className={`star ${
                          index + 1 <= (ratings[order.id] || 0) ? "active" : ""
                        }`}
                        onClick={() => handleStarClick(order.id, index + 1)}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Orders;
