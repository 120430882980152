import React, { useState, useRef } from "react";
import "../styles/Header.css";
import Logo from "../assets/VOGA.png";
import {
  IoBagHandleOutline,
  IoChevronDown,
  IoChevronUp,
  IoPersonCircleOutline,
} from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import Cart from "../pages/Cart";

const Header = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isShopDropdownOpen, setIsShopDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const getActiveNavItem = () => {
    const path = location.pathname;
    if (path === "/") return "Home";
    if (path.includes("/products/newarrivals")) return "New Arrivals";
    if (path.includes("/products/shop")) return "Shop";
    if (path === "/orders") return "Orders";
    if (path.includes("/products/bestsellers")) return "Best Sellers";
    return "";
  };

  const active = getActiveNavItem();

  const handleNavigation = (option) => {
    switch (option) {
      case "Home":
        setIsShopDropdownOpen(false);
        navigate("/");
        break;
      case "New Arrivals":
        setIsShopDropdownOpen(false);
        navigate("/products/newarrivals");
        break;
      case "Shop":
        setIsShopDropdownOpen((prev) => !prev);
        navigate("/");
        break;
      case "Orders":
        setIsShopDropdownOpen(false);
        navigate("/orders");
        break;
      case "Best Sellers":
        setIsShopDropdownOpen(false);
        navigate("/products/bestsellers");
        break;
      default:
        setIsShopDropdownOpen(false);
        navigate("/");
    }
  };

  const handleShopOptionClick = (option) => {
    setIsShopDropdownOpen(false); // Close dropdown
    navigate(`/products/${option.toLowerCase().replace(/\s+/g, "-")}`); // Navigate to the selected option
  };

  return (
    <div className="headerContainer" style={{ backgroundColor: "black" }}>
      <div className="logoContainer">
        <img
          src={Logo}
          alt="VOGA Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
      </div>

      <ul className="navLinks">
        {["Home", "New Arrivals", "Shop", "Best Sellers", "Orders"].map(
          (item) => (
            <li
              key={item}
              className={active === item ? "active" : ""}
              onClick={() => handleNavigation(item)}
            >
              {item}
              {item === "Shop" && (
                <div ref={dropdownRef}>
                  <span className="dropdownIcon">
                    {isShopDropdownOpen ? <IoChevronUp /> : <IoChevronDown />}
                  </span>
                  {isShopDropdownOpen && (
                    <ul className="shopDropdown">
                      {[
                        "Plain T-Shirts",
                        "Polo",
                        "Oversized",
                        "Full Sleeves",
                        "Plain T-Shirts",
                        "Polo",
                        "Oversized",
                        "Full Sleeves",
                      ].map((option) => (
                        <li
                          key={option}
                          onMouseEnter={(e) => e.stopPropagation()}
                          onMouseLeave={(e) => e.stopPropagation()}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShopOptionClick(option);
                          }}
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </li>
          )
        )}
      </ul>

      <div className="rightContainer">
        <IoBagHandleOutline
          className="cartIcon"
          onClick={() => setIsCartOpen(true)}
          style={{ color: "white" }}
        />
        {isLoggedIn ? (
          <div
            className="userInfo"
            style={{ color: "white" }}
            onClick={() => navigate("/profile")}
          >
            <IoPersonCircleOutline className="userIcon" />
            <span>Moin</span>
          </div>
        ) : (
          <>
            <button
              onClick={() => navigate("/authentication")}
              className="loginBtn"
            >
              Login
            </button>
            <button
              onClick={() => navigate("/authentication")}
              className="signupBtn"
            >
              Sign Up
            </button>
          </>
        )}
      </div>

      <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
    </div>
  );
};

export default Header;
