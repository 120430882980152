import React, { useState } from "react";
import "../styles/Home.css";
import { FaCircleArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ProductCard from "../components/ProductCard";
import { useNavigate } from "react-router-dom";

const images = [
  "https://www.beyoung.in/api/catalog/homepage-nov/17feb25/cotton/pale_grey_embroidered_applique_patch_shirt_zoom_view.jpg",
  "https://www.beyoung.in/api/catalog/homepage-nov/17feb25/cotton/rust_brown_diagonal_striped_shirt_base.jpg",
  "https://www.beyoung.in/api/catalog/homepage-nov/new_28jan25/Just-in-SHACKET.jpg",
  "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/soft_beige_textured_zipper_polo_t-shirt_base_12_02_2025_700x933.jpg",
  "https://www.beyoung.in/api/catalog/homepage-nov/homepage-jan25/Season_fabric/Products/Flannel/flannel-shirts-3.jpg",
];

const data = [
  {
    id: 1,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_t-shirts_for_men_15_8_2022/just_vibin_black_men_side_view_18_04_2023_700x933.jpg",
  },
  {
    id: 2,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_textured_oversized_t-shirt_side_view_16_07_2024_700x933.jpg",
  },
  {
    id: 3,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/navy_and_orange_striped_polo_t-shirt_for_men_base_15_07_2024_700x933.jpg",
  },
  {
    id: 4,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/polo_new_update_images_10_1_2022/white_flat_knit_solid_polo_shirt_for_men_base_02_07_2024_700x933.jpg",
  },
  {
    id: 5,
    title: "Black Polo Tshirt For Men",
    salesPrice: 1399,
    BuyPrice: 2999,
    discount: 53,
    category: "Polo Tshirt",
    image:
      "https://www.beyoung.in/api/cache/catalog/products/printed_oversized_t-shirt/pyar_ka_karz_printed_oversized_t-shirt_for_men_side_view_23_05_2024_700x933.jpg",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    setIndex((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="homeContainer">
      <h2>
        Where style speaks, trends resonate, <br /> fashion flourishes
      </h2>
      <p>
        Unveiling a fashion destination where trends blend seamlessly with your
        <br /> individual style aspirations. Discover today!
      </p>
      <div className="newCollectionBtn" onClick={() => navigate("/products")}>
        <p>New Collection</p>
        <FaCircleArrowRight />
      </div>
      <div className="slider-container">
        <div className="slider-wrapper">
          {images.map((src, i) => {
            const position = (i - index + images.length) % images.length;
            const offset = position - 2;
            const x = offset * 300;
            const y = Math.abs(offset) * 50;
            const scale = position === 2 ? 1.4 : 1;
            const zIndex = position === 2 ? 10 : 5;

            return (
              <motion.img
                key={i}
                src={src}
                alt={`Slide ${i + 1}`}
                className="slider-image"
                initial={{ opacity: 0, y: 50 }}
                animate={{
                  opacity: 1,
                  x: x,
                  y: y,
                  scale: scale,
                  zIndex: zIndex,
                }}
                transition={{ type: "spring", stiffness: 200, damping: 20 }}
              />
            );
          })}
        </div>

        <button
          onClick={prevSlide}
          className="slider-button slider-button-left"
        >
          <FaArrowLeft size={24} />
        </button>
        <button
          onClick={nextSlide}
          className="slider-button slider-button-right"
        >
          <FaArrowRight size={24} />
        </button>
      </div>
      <h3 className="newArrivalTagLine">NEW ARRIVALS</h3>
      <ProductCard data={data} width="19%" />
      <button className="newCollectionBtn">View All</button>

      <h3 className="newArrivalTagLine">SHOP</h3>
      <div className="collectionsOptions">
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/full_sleeves_new_update_images/plain_burgundy_full_sleeves_t-shirt_base_08_03_2023_700x933.jpg"
            alt="images"
          />
          <p>Plain T-Shirts</p>
        </div>
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/pick_any_3_2_new_polo/bold_black_polo_t-shirt_base_24_10_2024_700x933.jpg"
            alt="images"
          />
          <p>POLO</p>
        </div>
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/sand_brown_ribbed_texture_oversized_t-shirt_side_view_14_12_2024_700x933.jpg"
            alt="images"
          />
          <p>OVERSIZED</p>
        </div>
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/new_winter_bb_2023/sea_green___navy_blue_rugby_polo_oversized_men_sweatshirts_base2_22_11_2023_700x933.jpg"
            alt="images"
          />
          <p>FULL SLEEVES</p>
        </div>
      </div>
      <div className="collectionsOptions">
        <div className="collection">
          <img
            src="https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_mauve_dual_patch_color_block_shirt_base_18_11_2024_700x933.jpg"
            alt="images"
          />
          <p>SHIRTS</p>
        </div>
        <div className="collection">
          <img
            src="https://img.hollisterco.com/is/image/anf/KIC_330-5040-00197-900_model2.jpg?policy=product-large"
            alt="images"
          />
          <p>JEANS</p>
        </div>
        <div className="collection">
          <img
            src="https://img.hollisterco.com/is/image/anf/KIC_334-5029-00143-122_prod1?policy=product-medium"
            alt="images"
          />
          <p>TROUSERS</p>
        </div>
        <div className="collection">
          <img
            src="https://us.dockers.com/cdn/shop/files/01_3T_Ultimate_Chino_Prices_as_Marked_SRP_Khaki_9ac861fd-f978-4dd4-b7c2-3caad0038804_540x675_crop_center.jpg?v=1711144983"
            alt="images"
          />
          <p>CHINOS</p>
        </div>
      </div>

      <img
        style={{ width: "100%", margin: "50px 0" }}
        src="https://img.freepik.com/free-vector/twitch-banner-template-fashion-collection-style_23-2150721127.jpg?t=st=1740165253~exp=1740168853~hmac=a06073a95137534363e670955f6b97c75bec3d93a89aa9b5d29cb8a64407aba4&w=1800"
        alt="banner"
      />

      <h3 className="newArrivalTagLine">YOUR RECENT FAVES</h3>
      <ProductCard data={data} width="19%" />
    </div>
  );
};

export default Home;
