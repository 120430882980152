import React from "react";
import "../styles/Footer.css";
import { useNavigate } from "react-router-dom";

import { FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/voga.co.in/", "_blank");
  };

  const handleWhatsappClick = () => {
    window.open("https://wa.me/9082846069", "_blank");
  };

  return (
    <div id="footerContainer">
      <div id="footerDivider" />
      <div className="footerTopInfo">
        <div>
          <div className="innerFooterDiv">
            <h4>Customer Service</h4>
            <ul>
              <li onClick={() => navigate("/ordersanddelivery")}>
                Orders and delivery
              </li>
              <li onClick={() => navigate("/returnandrefund")}>
                Returns and refunds
              </li>
              <li onClick={() => navigate("/contactus")}>Contact us</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="innerFooterDiv">
            <h4>About VOGA</h4>
            <ul>
              <li onClick={() => navigate("/termsandconditions")}>
                Terms and conditions
              </li>
              <li onClick={() => navigate("/privacypolicy")}>Privacy policy</li>
              <li onClick={() => navigate("/aboutus")}>About us</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="innerFooterDiv">
            <h4>Follow us</h4>
            <ul className="socialIcons">
              <li onClick={handleInstagramClick}>
                <FaInstagram />
                Instagram
              </li>
              <li onClick={handleWhatsappClick}>
                <FaWhatsapp />
                Whatsapp
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footerBottomInfo">
        <p>&#169; Copyright 2025 VOGA India Limited. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
