import React, { useEffect } from "react";

const OrdersAndDelivery = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 20px",
        lineHeight: "1.8",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--secondary)",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "30px",
        }}
      >
        Orders & Delivery
      </h1>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        At VOGA, we strive to make your shopping experience seamless and
        enjoyable. Here’s everything you need to know about placing orders and
        delivery:
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Order Processing
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Once you place an order, our team immediately begins processing it. You
        will receive a confirmation email with your order details. Please ensure
        that your shipping address and contact information are accurate to avoid
        any delays. Our system is designed to handle orders efficiently,
        ensuring that your items are prepared for shipment as quickly as
        possible.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        If there are any issues with your order, such as out-of-stock items or
        payment discrepancies, our customer support team will contact you
        promptly. We aim to resolve any issues within 24 hours to ensure your
        order is not delayed.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Shipping & Delivery
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We guarantee delivery within 10 days of placing your order. Our
        logistics partners work around the clock to ensure your package reaches
        you on time. You will receive regular updates on your order status,
        including tracking information once your package is dispatched.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We offer multiple shipping options to cater to your needs. Standard
        shipping is free for all orders, while express shipping is available for
        an additional fee. Express shipping ensures delivery within 3–5 business
        days, depending on your location.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Once your order is dispatched, you will receive a tracking number via
        email. You can use this tracking number to monitor the progress of your
        delivery. If you experience any issues with tracking, please contact our
        support team for assistance.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        International Orders
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        At this time, VOGA does not accept international orders. We are
        currently focused on serving our local customers and ensuring the best
        possible shopping experience for them. We appreciate your understanding
        and look forward to expanding our services in the future.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Order Tracking
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Tracking your order is easy with VOGA. Once your order is dispatched,
        you will receive an email with a tracking number and a link to the
        courier’s website. You can use this information to monitor the status of
        your delivery in real-time.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        If you have any issues with tracking your order, please contact our
        customer support team. We are here to help and will provide you with the
        latest updates on your shipment.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Delivery Issues
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        While we strive to ensure smooth delivery, issues such as lost or
        damaged packages can occasionally occur. If you encounter any problems
        with your delivery, please contact us immediately. We will work with our
        logistics partners to resolve the issue as quickly as possible.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        For damaged packages, please take photos of the damaged items and the
        packaging. This will help us process your claim and arrange for a
        replacement or refund. We aim to resolve delivery issues within 48 hours
        of being notified.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Contact Us
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        If you have any questions about your order or delivery, feel free to
        contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        . Our customer support team is available 24/7 to assist you with any
        inquiries or concerns.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        You can also reach out to us via our social media channels or through
        the contact form on our website. We value your feedback and are always
        here to help improve your shopping experience.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Frequently Asked Questions
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        <strong>
          Q: Can I change my shipping address after placing an order?
        </strong>
        <br />
        A: Yes, you can change your shipping address within 24 hours of placing
        your order. Please contact our customer support team to make the change.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        <strong>Q: What should I do if my order is delayed?</strong>
        <br />
        A: If your order is delayed, please check the tracking information for
        updates. If the delay is significant, contact our support team for
        assistance.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        <strong>Q: Do you offer free returns?</strong>
        <br />
        A: Yes, we offer free returns within 5 days of receiving your order.
        Please refer to our Returns & Refunds policy for more details.
      </p>
    </div>
  );
};

export default OrdersAndDelivery;
