import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/ProductCard.css";

function ProductCard({ data, width }) {
  const navigate = useNavigate();

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 ]/g, "")
      .replace(/\s+/g, "-");
  };

  return (
    <div className="newArrivalProductContainer">
      {data.map((product) => {
        const slug = generateSlug(product.title);

        return (
          <div
            key={product.id}
            className="newArrivalProduct"
            style={{ width: width }}
            onClick={() => navigate(`/${slug}`, { state: { id: product.id } })}
          >
            <div className="newArrivalProductTop">
              <img src={product.image} alt="img" />
            </div>
            <div className="newArrivalProductBottom">
              <p className="title_">{product.title}</p>
              <p className="category_">{product.category}</p>
              <p className="price_">
                <b>₹{product.salesPrice}</b> <s>₹{product.BuyPrice}</s>{" "}
                <span>({product.discount}% off)</span>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProductCard;
