import React, { useState } from "react";
import { FaRegUser, FaRegEnvelope, FaRegStickyNote } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineMessage } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import "../styles/ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    alert("Your message has been sent!");
    setSubmitting(false);
  };

  return (
    <div className="contactContainer">
      <h1>Contact Us</h1>
      <div className="contactContent">
        <form onSubmit={handleSubmit} className="contactForm">
          <div className="formGroup">
            <label htmlFor="name">
              <FaRegUser className="formIcon" />
              <span>Name</span>
            </label>
            <input
              id="name"
              name="name"
              placeholder="Enter your name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="formGroup">
            <label htmlFor="email">
              <FaRegEnvelope className="formIcon" />
              <span>Email</span>
            </label>
            <input
              id="email"
              name="email"
              placeholder="Enter your email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="formGroup">
            <label htmlFor="subject">
              <FaRegStickyNote className="formIcon" />
              <span>Subject</span>
            </label>
            <input
              id="subject"
              name="subject"
              placeholder="Enter the subject"
              type="text"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>

          <div className="formGroup textarea">
            <label htmlFor="message">
              <MdOutlineMessage className="formIcon" />
              <span>Message</span>
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <button type="submit" disabled={submitting}>
            {submitting ? "Sending..." : "Send Message"}
          </button>
        </form>

        <div className="contactDetails">
          <a href="mailto:voga.website@gmail.com" className="contactItem">
            <FaRegEnvelope className="contactIcon" />
            <p>voga.website@gmail.com</p>
          </a>

          <a href="tel:+919082846069" className="contactItem">
            <IoCallOutline className="contactIcon" />
            <p>+91-9082846069</p>
          </a>

          <a
            href="https://wa.me/919082846069"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsappButton"
          >
            <FaWhatsapp className="whatsappIcon" />
            <span>Message us on WhatsApp</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
