import React, { useState } from "react";
import { IoPersonCircleOutline } from "react-icons/io5"; // Import the account icon
import "../styles/Profile.css";

const Profile = () => {
  const [user, setUser] = useState({
    name: "Moin Khan",
    email: "moin@gmail.com",
    phone: "+91-9876543210",
    address: {
      street: "",
      pincode: "",
      city: "",
      state: "",
    },
  });

  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  const saveAddress = () => {
    setIsEditingAddress(false);
    alert("Address saved successfully!");
  };

  const handlePasswordChange = () => {
    if (newPassword === confirmPassword) {
      alert("Password updated successfully!");
      setIsChangingPassword(false);
      setNewPassword("");
      setConfirmPassword("");
    } else {
      alert("Passwords do not match!");
    }
  };

  return (
    <div className="profile-container">
      {/* Profile Header */}
      <div className="profile-header">
        <div className="profile-icon">
          <IoPersonCircleOutline className="account-icon" />
        </div>
        <h1 className="profile-name">{user.name}</h1>
        <p className="profile-email">{user.email}</p>
        <p className="profile-phone">{user.phone}</p>
      </div>

      {/* Address Section */}
      <div className="profile-section">
        <h2 className="section-title">Address</h2>
        {!isEditingAddress ? (
          <div className="address-card">
            {user.address.street ? (
              <>
                <p>{user.address.street}</p>
                <p>
                  {user.address.city}, {user.address.state} -{" "}
                  {user.address.pincode}
                </p>
                <button
                  className="action-button edit-address"
                  onClick={() => setIsEditingAddress(true)}
                >
                  Edit Address
                </button>
              </>
            ) : (
              <button
                className="action-button add-address"
                onClick={() => setIsEditingAddress(true)}
              >
                Add Address
              </button>
            )}
          </div>
        ) : (
          <div className="address-form">
            <input
              type="text"
              name="street"
              placeholder="Address"
              value={user.address.street}
              onChange={handleAddressChange}
            />
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={user.address.pincode}
              onChange={handleAddressChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={user.address.city}
              onChange={handleAddressChange}
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={user.address.state}
              onChange={handleAddressChange}
            />
            <button
              className="action-button save-address"
              onClick={saveAddress}
            >
              Save Address
            </button>
            <button
              className="action-button cancel-address"
              onClick={() => setIsEditingAddress(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>

      {/* Change Password Section */}
      {isChangingPassword ? (
        <div className="profile-section">
          <h2 className="section-title">Change Password</h2>
          <div className="password-form">
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              className="action-button update-password"
              onClick={handlePasswordChange}
            >
              Update Password
            </button>
            <button
              className="action-button cancel-password"
              onClick={() => setIsChangingPassword(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="profile-actions">
          <button
            className="action-button change-password"
            onClick={() => setIsChangingPassword(true)}
          >
            Change Password
          </button>
          <button className="action-button logout">Log Out</button>
        </div>
      )}
    </div>
  );
};

export default Profile;
