import React, { useEffect } from "react";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 20px",
        lineHeight: "1.8",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--secondary)",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "30px",
        }}
      >
        About Us
      </h1>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Welcome to VOGA, your go-to destination for fashion and lifestyle
        products. Our mission is to bring you the latest trends and unique
        products from a diverse range of vendors. Whether you're looking for
        stylish clothing, accessories, or home decor, VOGA has something for
        everyone.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Our Story
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        VOGA was founded in 2024 with the vision of creating a platform where
        independent vendors and designers can showcase their creativity. We
        believe in supporting small businesses and providing customers with
        high-quality, unique products that you won't find anywhere else.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Our journey began with a small team of fashion enthusiasts who wanted to
        revolutionize the way people shop online. Today, VOGA has grown into a
        thriving community of vendors and customers who share a passion for
        style and innovation.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Our Mission
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        At VOGA, our mission is simple: to make fashion accessible, affordable,
        and enjoyable for everyone. We strive to offer a curated selection of
        products that cater to diverse tastes and preferences. Whether you're
        looking for everyday essentials or statement pieces, we've got you
        covered.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Our Values
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        At the heart of VOGA are our core values:
      </p>
      <ul
        style={{
          fontSize: "1.1rem",
          marginBottom: "20px",
          paddingLeft: "20px",
        }}
      >
        <li>
          <strong>Transparency:</strong> We believe in being open and honest
          with our customers. From pricing to product details, we ensure you
          have all the information you need to make informed decisions.
        </li>
        <li>
          <strong>Quality:</strong> We are committed to offering high-quality
          products that meet your expectations. Every item on our platform is
          carefully vetted to ensure it meets our standards.
        </li>
        <li>
          <strong>Customer Satisfaction:</strong> Your happiness is our
          priority. We go above and beyond to provide exceptional customer
          service and a seamless shopping experience.
        </li>
        <li>
          <strong>Community:</strong> We are more than just a marketplace; we
          are a community of fashion lovers, designers, and vendors who share a
          passion for creativity and style.
        </li>
      </ul>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Why Choose VOGA?
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        VOGA stands out from the crowd for several reasons:
      </p>
      <ul
        style={{
          fontSize: "1.1rem",
          marginBottom: "20px",
          paddingLeft: "20px",
        }}
      >
        <li>
          <strong>Unique Products:</strong> Discover one-of-a-kind items from
          independent vendors and designers.
        </li>
        <li>
          <strong>Affordable Prices:</strong> We believe that great style
          shouldn't come with a hefty price tag.
        </li>
        <li>
          <strong>Fast Delivery:</strong> We guarantee delivery within 10 days,
          so you can enjoy your purchases sooner.
        </li>
        <li>
          <strong>Easy Returns:</strong> Not happy with your purchase? No
          problem! We offer hassle-free returns within 5 days.
        </li>
      </ul>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Join the VOGA Community
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We invite you to join the VOGA community and be a part of our journey.
        Whether you're a customer, vendor, or fashion enthusiast, there's a
        place for you here. Follow us on social media to stay updated on the
        latest trends, promotions, and events.
      </p>

      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        For any inquiries, feel free to contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        . We look forward to hearing from you!
      </p>
    </div>
  );
};

export default AboutUs;
