import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  return (
    <div>
      {location.pathname !== "/authentication" && <Header />}
      <Main />
      <Footer />
    </div>
  );
}

export default App;
