import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Product from "../pages/Product";
import ProductDetails from "../pages/ProductDetails";
import Authentication from "../pages/Authentication";
import Orders from "../pages/Orders";
import Profile from "../pages/Profile";
import OrdersAndDelivery from "../pages/OrdersAndDelivery";
import ReturnAndRefund from "../pages/ReturnAndRefund";
import ContactUs from "../pages/ContactUs";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUs from "../pages/AboutUs";

const Main = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Product />} />
        <Route path="/products/:category" element={<Product />} />
        <Route path="/:slug" element={<ProductDetails />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/ordersanddelivery" element={<OrdersAndDelivery />} />
        <Route path="/returnandrefund" element={<ReturnAndRefund />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/termsandconditions" element={<TermsAndCondition />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
    </div>
  );
};

export default Main;
