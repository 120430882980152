import React, { useEffect } from "react";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 20px",
        lineHeight: "1.8",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1
        style={{
          color: "var(--secondary)",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "30px",
        }}
      >
        Terms & Conditions
      </h1>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        By using the VOGA website, you agree to the following terms and
        conditions. Please read them carefully before placing an order or using
        our services. If you do not agree with any part of these terms, you must
        not use this website.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Eligibility
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        You must be at least 18 years old to use this website. By placing an
        order, you confirm that you are legally capable of entering into a
        binding contract. If you are under 18, you may only use this website
        with the involvement of a parent or guardian.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Product Information
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We make every effort to display accurate product information, including
        prices, descriptions, and availability. However, errors may occur, and
        we reserve the right to correct them at any time. In the event of a
        pricing error, we will notify you and provide the option to cancel your
        order or pay the correct price.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Product images on the website are for illustrative purposes only. Actual
        products may vary slightly in color, size, or design due to
        manufacturing differences.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Orders and Payments
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        All orders are subject to availability and confirmation of the order
        price. We reserve the right to refuse or cancel any order at our
        discretion. Payments must be made in full at the time of purchase. We
        accept various payment methods, including credit/debit cards and digital
        wallets.
      </p>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        In the event of a payment failure, your order will not be processed. If
        your payment is successful but your order is canceled, we will issue a
        full refund to your original payment method within 5-7 business days.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Limitation of Liability
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        VOGA is not liable for any indirect, incidental, or consequential
        damages arising from the use of this website or the products purchased.
        This includes, but is not limited to, loss of profits, data, or business
        opportunities. Our liability is limited to the maximum extent permitted
        by law.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Intellectual Property
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        All content on this website, including text, images, logos, and designs,
        is the property of VOGA and is protected by intellectual property laws.
        You may not reproduce, distribute, or use any content from this website
        without our prior written consent.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Governing Law
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        These terms and conditions are governed by and construed in accordance
        with the laws of India. Any disputes arising from the use of this
        website will be subject to the exclusive jurisdiction of the courts of
        India.
      </p>

      <h2
        style={{
          color: "var(--secondary)",
          fontSize: "1.8rem",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        Changes to Terms
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        We reserve the right to update or modify these terms and conditions at
        any time without prior notice. Your continued use of the website after
        any changes constitutes your acceptance of the revised terms.
      </p>

      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        If you have any questions about these terms and conditions, please
        contact us at{" "}
        <a
          href="mailto:voga.website@gmail.com"
          style={{ color: "var(--secondary)", textDecoration: "none" }}
        >
          voga.website@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default TermsAndCondition;
